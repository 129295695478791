export type MessageStatus = "SEEN" | "DELIVERED" | "SENT";

export type Inbox = {
  id: string;
  name: string;
  image_url: string;
  leader_id: number;
  phone: string;
  lastMessage?: string;
  timestamp?: string;
  messageStatus?: MessageStatus;
  notificationsCount?: number;
  conversationActive?: boolean;
  isPinned?: boolean;
  isOnline?: boolean;
};

// chat filter options
export const CHAT_FILTER = {
    ALL: "ALL",
    ACTIVE: "ACTIVE",
    NEW: "NEW",
};
