import React, {useState, useEffect, useRef} from "react";
import { inbox } from "../data/inbox";
import { Inbox, CHAT_FILTER } from "common/types/common.type";
import {getContacts, getConversations} from "./requests";
import {getSessionId} from "../../../common/utils/storage";

type User = {
  name: string;
  image: string;
};

type ChatContextProp = {
  user: User;
  inbox: Inbox[];
  activeChat?: Inbox;
  onChangeChat: (chat: Inbox) => void;
  handleChatFilter?: (filter: string) => void;
  reloadChat?: () => void;
};

const initialValue: ChatContextProp = {
  user: { name: "", image: "/assets/images/girl.jpeg" },
  inbox,
  onChangeChat() {
    throw new Error();
  },
};

export const ChatContext = React.createContext<ChatContextProp>(initialValue);

export default function ChatProvider(props: { children: any }) {
  const { children } = props;

  const [user] = useState<User>(initialValue.user);
  const [inbox, setInbox] = useState<Inbox[]>([]); // Use state for inbox
  const [activeChat, setActiveChat] = useState<Inbox>();
  const [error, setError] = useState<string | null>(null); // State to handle errors

  const [chatFilter, setChatFilter] = useState<string>(CHAT_FILTER.ACTIVE);
  const [chatRefresh, setChatRefresh] = useState<boolean>(false);

  const contactsIntervalRef = useRef<number | null>(null);
  const fetchConversations = async () => {
    if(chatFilter === CHAT_FILTER.NEW) {
        fetchContacts();
        return;
    }
    const response = await getConversations(chatFilter === CHAT_FILTER.ALL);
    setInbox(response);
  };

  const fetchContacts = async () => {
    const res = await getContacts();
    setInbox(res);
  }

  // Fetch contacts when the component mounts
  useEffect(() => {
    fetchConversations();

    if(chatFilter !== CHAT_FILTER.NEW) {
      contactsIntervalRef.current = setInterval(fetchConversations, 10000) as unknown as number; // Fetch contacts every 5 seconds
    }
    
    return () => { clearInterval(contactsIntervalRef.current as number); }; // Clear the interval when the component unmounts
  }, [chatFilter, chatRefresh]);

  const handleChangeChat = (chat: Inbox) => {
    setActiveChat(chat);
  };

  const handleChatFilter = (filter: string) => {
    setChatFilter(filter);
  };

  const reloadChat = () => {
    setChatRefresh(!chatRefresh);
  };

  useEffect(() => {
    if(!getSessionId())
        window.location.href = '/session-expired';
  }, []);

  return (
    <ChatContext.Provider value={{ user, inbox, activeChat, onChangeChat: handleChangeChat, handleChatFilter, reloadChat }}>
      {children}
    </ChatContext.Provider>
  );
}

export const useChatContext = () => React.useContext(ChatContext);
