import {Inbox} from "../../../common/types/common.type";
import {clearSessionId, getSessionId} from "../../../common/utils/storage";

export const getConversations = async (allConversations: boolean = false) => {
    try {
        let url = 'https://backend.unifysocials.com/get-conversations';
        if (allConversations)
            url += '/1';
        else
            url += '/0';

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'whatsapp-session-id': getSessionId() || '',
            }
        });

        if (!response.ok) {
            if(response.status === 403) {
                clearSessionId();
                window.location.href = '/session-expired';
            }
            else {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
        }

        const data: Inbox[] = await response.json();
        return data;
    } catch (error: any) {
        throw new Error(error.message);
    }
};

export const getContacts = async () => {
    try {
        const response = await fetch('https://backend.unifysocials.com/messages/get-contacts', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'whatsapp-session-id': getSessionId() || '',
            }
        });

        if (!response.ok) {
            if(response.status === 403) {
                clearSessionId();
                window.location.href = '/session-expired';
            }
            else {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
        }

        const data: Inbox[] = await response.json();
        return data;
    } catch (error: any) {
        throw new Error(error.message);
    }
};