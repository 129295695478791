
export const setSessionId = (sessionId: string) => {
    localStorage.setItem("session_id", sessionId);
};

export const getSessionId = () => {
    return localStorage.getItem("session_id");
};

export const clearSessionId = () => {
    localStorage.removeItem("session_id");
};