import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import ChatProvider from "pages/chat/context/chat";
const ChatPage = React.lazy(() => import("pages/chat/chat-room-page"));
const UnSelectedChatPage = React.lazy(() => import("pages/chat/unselected-page"));
const AuthPage = React.lazy(() => import("pages/chat/auth"));
const SessionExpiredPage = React.lazy(() => import("pages/session"));
const NotFoundPage = React.lazy(() => import("pages/not_found"));

const routes = [
    {
        path: "/auth/:sessionId",
        element: <AuthPage />,
    },
    {
        path: "/session-expired",
        element: <SessionExpiredPage />, // Route for session expiration
    },
    {
        path: "/",
        element: (
            <ChatProvider>
                <UnSelectedChatPage />
            </ChatProvider>
        ),
    },
    {
        path: "/:id",
        element: (
            <ChatProvider>
                <ChatPage />
            </ChatProvider>
        ),
    },
    {
        path: "*",
        element: <NotFoundPage />, // Catch-all route for 404
    },
];

export default function AppRoutes() {
    const router = createBrowserRouter(routes);
    return <RouterProvider router={router} />;
}
